import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1024.000000 1024.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)">
        <path d="M4995 9629 c-188 -10 -338 -40 -496 -100 -30 -11 -65 -24 -80 -28
-14 -5 -77 -34 -139 -66 -231 -116 -452 -285 -641 -491 -63 -70 -279 -353
-279 -367 0 -2 -24 -45 -54 -95 -233 -387 -389 -909 -427 -1427 -12 -163 -6
-577 11 -679 5 -34 17 -108 25 -166 53 -354 171 -715 339 -1035 60 -114 180
-314 195 -325 4 -3 18 -21 31 -40 105 -153 310 -367 480 -502 320 -253 674
-394 1075 -428 205 -18 474 14 689 81 751 233 1371 972 1596 1902 48 199 90
469 90 578 l0 61 -317 -6 c-175 -4 -457 -11 -628 -16 -170 -5 -427 -12 -570
-15 -143 -3 -347 -8 -452 -11 l-193 -6 0 -34 0 -34 153 5 c174 7 763 22 1166
32 l274 6 -7 -59 c-27 -233 -129 -564 -239 -779 -219 -428 -564 -744 -952
-874 -166 -55 -241 -66 -455 -66 -214 1 -290 13 -458 75 -204 74 -346 163
-541 335 -35 31 -155 164 -206 229 -217 275 -373 656 -444 1081 -20 118 -33
390 -27 535 24 522 189 991 479 1362 86 111 251 268 362 347 134 94 329 185
486 225 148 39 389 47 550 20 241 -41 487 -158 704 -334 76 -62 245 -235 245
-250 0 -17 26 -11 44 9 17 19 16 21 -25 68 -69 77 -200 206 -254 250 -150 122
-381 246 -539 289 -186 51 -229 57 -401 57 -243 1 -397 -33 -602 -134 -239
-118 -472 -312 -638 -534 -74 -98 -182 -276 -222 -365 -18 -41 -38 -84 -43
-95 -37 -80 -99 -264 -125 -369 -34 -143 -45 -200 -66 -366 -41 -329 -9 -712
87 -1050 48 -167 92 -278 175 -444 60 -120 155 -273 210 -341 216 -264 396
-414 643 -534 271 -133 541 -176 819 -132 320 51 593 193 842 438 112 109 159
164 234 273 123 179 197 319 272 517 61 162 139 479 139 568 0 23 6 38 18 44
14 7 359 17 440 12 15 -1 13 -44 -14 -226 -137 -925 -652 -1717 -1344 -2065
-478 -240 -1017 -259 -1514 -54 -501 207 -944 654 -1220 1228 -164 340 -245
618 -307 1056 -22 148 -19 699 4 860 105 738 397 1344 865 1794 40 38 83 77
95 85 12 8 24 18 27 21 10 13 157 117 221 157 190 119 468 225 679 259 166 27
435 25 590 -4 262 -48 515 -151 738 -300 188 -126 364 -285 494 -447 52 -65
65 -72 80 -40 10 21 6 30 -27 72 -66 82 -296 306 -380 370 -116 87 -250 174
-331 215 -170 86 -412 170 -569 198 -99 18 -299 26 -440 19z"/>
<path d="M3947 7869 c-16 -249 -20 -449 -10 -449 6 0 13 17 17 38 9 56 61 216
82 251 10 18 34 42 54 56 59 41 115 47 342 40 l208 -8 5 -31 c4 -17 5 -407 3
-866 -4 -901 -3 -878 -57 -995 -18 -38 -83 -106 -127 -131 -39 -23 -2 -26 123
-9 70 9 138 19 150 21 l22 4 -6 1063 c-3 584 -7 1064 -9 1066 -2 2 -108 8
-236 12 -227 8 -442 23 -517 35 l-38 6 -6 -103z"/>
<path d="M4871 7334 c1 -324 5 -803 8 -1064 l6 -475 148 -3 c96 -2 147 1 147
8 0 5 -6 10 -13 10 -27 0 -96 72 -122 126 -60 128 -63 166 -69 1161 -4 548 -3
673 8 682 19 16 345 4 415 -16 30 -8 69 -26 87 -40 54 -41 104 -167 104 -265
0 -26 4 -49 9 -52 15 -10 20 50 23 284 2 219 2 225 -17 222 -42 -6 -425 -3
-577 3 l-158 8 1 -589z"/>
<path d="M6535 7160 c-308 -4 -728 -11 -933 -15 l-373 -7 3 -32 3 -31 255 3
c140 2 518 7 840 12 322 5 693 10 825 10 133 1 245 4 251 8 5 4 9 13 8 20 -1
6 -2 19 -3 27 -1 13 -25 15 -158 13 -87 -1 -410 -5 -718 -8z"/>
<path d="M2700 3478 c-85 -29 -174 -113 -217 -208 -113 -245 -111 -736 3 -961
30 -61 107 -137 164 -163 35 -16 65 -21 135 -21 85 0 95 2 178 42 l87 42 0
311 c0 240 -3 312 -12 314 -31 10 -138 15 -138 7 0 -5 8 -11 18 -13 11 -3 31
-29 45 -59 26 -52 27 -57 27 -245 0 -106 -4 -203 -9 -217 -13 -33 -64 -79
-103 -94 -42 -15 -132 -16 -182 -2 -46 13 -120 79 -147 130 -10 21 -31 77 -46
126 -25 85 -26 97 -27 328 -1 221 1 246 21 318 32 108 59 161 108 212 61 62
111 85 205 92 67 5 88 2 138 -16 86 -33 167 -120 188 -204 13 -53 19 -10 16
107 l-3 116 -38 20 c-117 59 -296 76 -411 38z"/>
<path d="M3356 3330 c-4 -83 -5 -154 -2 -158 3 -3 6 6 6 21 0 41 40 140 67
166 14 13 41 27 61 32 43 10 196 12 241 3 l31 -6 0 -487 c0 -546 -5 -594 -61
-664 l-31 -38 64 7 c34 4 66 9 71 11 4 2 7 282 7 622 l0 618 -197 7 c-109 3
-210 9 -224 12 l-26 6 -7 -152z"/>
<path d="M4569 3445 c68 -62 73 -88 78 -480 7 -493 -8 -610 -90 -681 l-42 -37
32 6 c18 4 62 9 98 13 l65 7 0 593 c0 326 -3 595 -7 597 -5 3 -46 8 -92 12
l-84 7 42 -37z"/>
<path d="M4900 3461 l-105 -6 -3 -587 -2 -588 62 -2 c35 -1 90 -3 123 -4 330
-11 502 224 482 659 -9 210 -49 320 -151 423 -96 96 -178 118 -406 105z m267
-87 c90 -39 137 -94 204 -235 36 -77 37 -418 1 -524 -64 -192 -176 -280 -351
-277 -42 0 -89 5 -106 10 -55 16 -59 49 -58 555 l1 448 22 19 c21 18 38 23
110 33 47 7 125 -6 177 -29z"/>
<path d="M3888 3401 c-1 -31 -2 -309 0 -618 l3 -563 75 0 75 0 -35 36 c-61 63
-66 103 -66 640 0 258 0 475 0 483 0 11 24 13 128 9 74 -2 136 -9 150 -16 31
-18 53 -55 68 -115 l12 -52 3 95 c1 52 3 109 4 125 l1 30 -207 1 -207 1 -4
-56z"/>
<path d="M5990 3413 c79 -58 139 -143 127 -182 -6 -19 -35 -96 -116 -306 -35
-88 -78 -202 -96 -253 -51 -138 -120 -273 -164 -321 l-39 -42 41 7 c23 4 78 7
121 6 l80 -2 -28 29 c-55 58 -45 136 46 358 l29 71 142 4 c91 3 146 0 153 -6
20 -19 105 -286 106 -333 0 -32 -6 -53 -22 -75 -20 -27 -20 -29 -4 -25 11 3
40 9 67 13 26 3 47 9 47 13 -1 7 -12 42 -115 336 -42 121 -110 318 -151 439
-93 271 -95 276 -137 276 -17 0 -50 3 -72 6 l-40 5 25 -18z m225 -414 c26 -77
45 -143 42 -146 -9 -8 -221 -3 -229 5 -4 4 15 66 43 137 28 72 56 141 61 155
9 23 10 24 23 7 7 -9 34 -81 60 -158z"/>
<path d="M6210 3398 c0 -12 88 -276 190 -568 18 -52 61 -176 95 -275 68 -196
64 -192 148 -194 l38 -1 -39 39 c-38 39 -95 143 -127 231 -28 80 -42 120 -154
450 -93 277 -113 325 -130 328 -13 2 -21 -2 -21 -10z"/>
<path d="M6756 3369 c-3 -17 -6 -64 -5 -103 l0 -71 20 53 c30 77 45 83 180 80
l114 -3 3 -350 c3 -408 -4 -498 -42 -555 -29 -44 -25 -47 43 -34 l31 7 -2 491
-3 491 -144 7 c-79 4 -154 9 -166 12 -19 5 -24 1 -29 -25z"/>
<path d="M7170 2880 l0 -490 56 0 56 0 -30 34 c-46 52 -52 119 -49 531 l2 360
75 3 c41 2 89 1 106 -3 40 -7 81 -42 90 -78 10 -41 14 -29 18 55 l4 74 -164 2
-164 3 0 -491z"/>
<path d="M7768 3344 c35 -18 92 -103 92 -137 0 -24 -149 -481 -202 -617 -22
-58 -53 -122 -70 -143 -16 -21 -28 -39 -27 -40 2 -1 39 -1 82 1 78 4 79 4 60
24 -30 33 -23 133 16 251 19 54 40 104 48 112 11 11 40 15 114 15 l100 0 16
-37 c18 -44 37 -109 60 -211 16 -69 16 -75 -1 -102 -16 -29 -16 -30 1 -30 10
0 29 3 41 7 21 5 23 10 18 37 -6 29 -126 438 -186 631 -15 50 -37 123 -49 162
-12 40 -27 75 -32 79 -6 3 -33 8 -62 10 l-52 5 33 -17z m163 -345 c20 -68 35
-127 32 -131 -5 -9 -167 -11 -175 -2 -4 4 45 165 77 252 12 33 33 -4 66 -119z"/>
<path d="M7925 3330 c3 -5 28 -87 55 -182 28 -95 56 -191 64 -213 8 -22 35
-112 61 -200 25 -88 53 -185 62 -215 21 -71 29 -80 70 -80 l34 0 -24 33 c-32
42 -59 98 -85 172 -38 110 -202 672 -202 691 0 2 -9 4 -21 4 -11 0 -17 -4 -14
-10z"/>
<path d="M2367 3278 c-47 -62 -92 -168 -119 -278 -8 -33 -13 -112 -13 -200 1
-150 13 -223 57 -335 19 -48 78 -149 83 -143 2 2 -7 41 -21 86 -62 217 -53
617 20 834 12 37 21 68 20 68 -2 0 -14 -15 -27 -32z"/>
<path d="M5495 3212 c63 -184 60 -512 -5 -686 -19 -51 -19 -51 0 -26 35 44 81
150 96 218 18 85 18 218 0 303 -15 67 -61 178 -90 214 -15 19 -15 17 -1 -23z"/>
<path d="M3198 2847 l-58 -9 0 -290 0 -290 23 14 c21 15 22 21 27 234 5 199 8
223 28 269 13 27 28 52 34 56 26 16 -1 24 -54 16z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
